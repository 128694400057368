import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader";



export const LayoutColumn = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const LayoutRow = styled.section`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    margin-top: ${({top}) => top? '3rem':''};
`

export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`

export const LoadingCont = styled.section`
    height: 100vh;
    display: grid;
`