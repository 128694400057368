import styled from 'styled-components'


export const  HeaderWrapper = styled.div`
    background: ${({color}) => color};
    color: ${({col}) => col? 'black':'white'};
    text-align: center;
    display: flex;
    gap: ${({gp}) => gp};
    justify-content: ${({gp}) => gp? '':'space-between'};
    border-bottom: ${({color}) => color === 'transparent'? '1px solid rgba(217, 217, 217, 1)':'none'};
    padding: 3.4%;
    cursor: pointer;
    &.select {
        background: #0C8CE9;
        color: white;
    }
`
export const HeaderContent = styled.h1`
    align-self: center;
`

export const HeaderIcon = styled.img`

`