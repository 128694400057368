import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader"


export const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({wt}) => wt? wt:'26%'};
    padding: 2%;
    gap: ${({wt}) => wt? '1rem':''};
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    margin-top: ${({top}) => top? '2rem':''}
`
export const UserLabel = styled.label`
    font-weight: 700;
    font-size: 1.2rem;
`
export const UserText = styled.p`
    color: ${({color}) => color? color : 'red'};
    font-size: 0.8rem;
    text-decoration: ${({decor}) => decor? decor : ''};
`
export const UserProfile = styled.img`
    height: 13rem;
    border-radius: 6px;
    margin-bottom: 3rem;
`
export const UserImage = styled.img`
    border-radius: 999px;
    height: 8rem;
    width: 8rem;
    margin-bottom: 3%;
`

export const UserInput = styled.input`
    height: 3rem;
    background: rgba(238, 238, 238, 1);
    margin-bottom: 70px;
    border: none;
    border-radius: 3px;
    padding-left: 4%;
`
export const UserButton = styled.button`
    background: ${({color}) => color? 'rgba(238, 238, 238, 1)' : 'rgba(17, 115, 171, 1)' };
    border: none;
    height: 3rem;
    color: white;
    width: 38%;
    border-radius: 4px;
    &:hover{
        background: ${({color}) => color? 'rgb(187 180 180)' : 'rgb(11 80 119);' };
    }
`
export const UserButtonWrap = styled.span`
    display: flex;
    justify-content: space-between;
    flex-direction: ${({gap}) => gap? 'column':'row'};
    gap: ${({gap}) => gap? '0.3rem':''};
`
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`