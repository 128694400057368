import { useState, useEffect } from 'react'
import { AccountWrapper, AcctIcon, AcctProfile, AcctText } from './style'
import  down from '../../assets/icons/down.svg'
import { useDispatch, useSelector } from 'react-redux'
import { click } from '../../features/registerSlice/rdataSlice'


const AccountInfo = ({user_name, user_img, user_id, al, index, align, icon, alt, color, tcolor, wt, ic, center, out}) => {
  const [bcolor, setBColor] = useState({
    bg: 'transparent',
    text: 'black'
  });
  const dispatch = useDispatch();
  const states = useSelector((state) => state.data.controls);
  const user = useSelector((state) => state.signin.user);
  const stacks = Array.from(document.querySelectorAll('.accts'));
  function popEdit(index){
    stacks.forEach((stack) => {
      stack.classList.add('base')
      if(stack === stack[index]){
        stack.classList.remove('base')
      }
    });
    setBColor({bg: '#0C8CE9', text: 'white'});
    
    dispatch(click({changepass: true, id: user_id, user_name: user_name, profile: user_img}));
  }
  useEffect(() => {
    if(!states.changepass){
      setBColor({
        bg: 'transparent',
        text: 'black'
      })
    }
  }, [states.changepass])
  return (
    <AccountWrapper key={user_id} className='accts' cont={align} color={color? color: bcolor.bg} wt={wt} center={center} 
        onClick={ic? 
              bcolor.bg === '#0C8CE9'? () => setBColor({bg: 'transparent', text: 'black'}) : () => popEdit(index)
            : () => console.log('psyche')}>
        <AcctProfile src={user_img} alt={user_name}/>
        <AcctText tcolor={tcolor? tcolor:bcolor.text} al={al} cont={align}>
          {user_name}
        </AcctText>
        {user.id === user_id && <AcctText tcolor={tcolor? tcolor:bcolor.text}>You</AcctText>}
        {ic && <AcctIcon src={icon} alt={alt} />}
        {out && <AcctIcon src={down} alt={"dropdown"} onClick={states.logout? () => dispatch(click({logout: false})) : () => dispatch(click({logout: true}))} />}
        {/* {
          user? 
          <>
            <AcctText tcolor={tcolor? tcolor:bcolor.text}>You</AcctText>
            <AcctIcon src={icon} alt={alt} />
          </>
          :
          <AcctIcon src={down} alt={"dropdown"} onClick={states.logout? () => dispatch(click({logout: false})) : () => dispatch(click({logout: true}))} /> */}
        {/* <AcctIcon src={icon?icon:down} alt={icon?alt:"dropdown"} /> */}
    </AccountWrapper>
  )
}

export default AccountInfo