import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { NavBar, NavIcon } from './style'
import burger from '../../assets/icons/burger.svg'
import close from '../../assets/icons/closew.svg'
import profile from '../../assets/images/av1.svg'
import AccountInfo from '../acct_bar'
import { menu } from '../../features/singleSlice/single';
import PopLogOut from '../popup/logout';

const Bar = () => {
  const user = useSelector((state) => state.signin.user);
  const state = useSelector((state) => state.single.twitch);
  const logout = useSelector((state) => state.data.controls.logout);
  const dispatch = useDispatch();
  return (
    <>
    {logout && <PopLogOut />}
    <NavBar>
        {state?
          <NavIcon src={close} alt="menu" className='rotate' onClick={() => dispatch(menu(state? false: true))} />
          :<NavIcon src={burger} alt="menu" className='expand' onClick={() => dispatch(menu(state? false: true))} />}
        <AccountInfo user_name={user.email} tcolor={'white'} wt={'23%'} user_img={user.profile? user.profile.avatar: profile} out/>
    </NavBar>
    </>
  )
}

export default Bar