import React, { useEffect } from 'react'
import { HeaderWrapper, Icon, ModalWrapper, Text, BackDrop } from '../style'
import ExButton from '../../button'
import cross from '../../../assets/icons/cross.svg'
import done from '../../../assets/icons/done.svg'
import { useDispatch } from 'react-redux'
import { click } from '../../../features/registerSlice/rdataSlice'
import { clear } from '../../../features/postSlice/post'
import { clearp } from '../../../features/putSlice/put'

const PopUpDone = ({text}) => {
  const dispatch = useDispatch();
  function close(){
    dispatch(click({done: false}));
  }
  useEffect(() => {
    dispatch(clear());
    dispatch(clearp());
  }, [])
  return (
    <>
        <BackDrop></BackDrop>
        <ModalWrapper>
            <HeaderWrapper>
                <Text>Success</Text>
                <Icon src={cross} alt='close' point onClick={() => close()} />
            </HeaderWrapper>
            <Icon src={done} alt='success'/>
            <Text className='special-text'>{text}</Text>
            <ExButton term={'Refute Documents'} text={'Back'} func={() => close()} className='center-button' at={'auto'} wt={'36%'} ht={'3rem'} />
        </ModalWrapper>
    </>
  )
}

export default PopUpDone