import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { signinData, errorClear, successClear } from "../../features/signinSlice/signin";
import { setAlert } from '../../features/postSlice/post';
import { Form, FormButton, FormFailed, FormIcon, FormInput, FormLogo, FormMini, FormText, FormWrapper, Spinner } from './style'
import eye from "../../assets/icons/eye.svg";
import eyes from "../../assets/icons/eye-slash.svg";
import logo from '../../assets/images/loginlogo.svg';
import { viewPassword } from "../../utility";

const FormLogin = () => {
    const [show, setShow] = useState(false);
    const[check, setCheck] = useState(false);
    const [loginInfo, setLoginInfo] = useState(
        {email: "", password: ""}
        )
    const url = `${process.env.REACT_APP_IN_URL}`
    const config = { "Content-Type": "application/json" }
    const user = useSelector((state) => state.signin);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(signinData({url: url, loginInfo: loginInfo, config: config}));
    }
    
    useEffect(() => {
        if (user.loading === false && user.error !== ""){
            if(user.status === 400){
                dispatch(setAlert({alert: true, message: user.error}));
                dispatch(errorClear());
                setShow(true);
              }else{
                dispatch(setAlert({alert: true, message: 'Network Error'}));
                dispatch(errorClear());
              }
          }else if (user.loading === false && user.error === "" && user.success === true){
            dispatch(successClear());
            navigate("/verify");
          }
    }, [user.loading])
    const handleChange =  (event) => {
        setCheck(false);
        const target = event.target;
        setLoginInfo({
            ...loginInfo, [target.name]: target.value
        });
    }
return (
   <FormWrapper>
        <FormLogo src={logo} alt="Pinearth" />
        <Form onSubmit={handleSubmit}>
            <FormInput type='email' warn={check} name='email' placeholder='Email' onChange={handleChange} required />
            {check? <FormFailed>Incorrect email or password</FormFailed>: <></>}
            <FormMini>
            <FormInput type='password' warn={check} name='password' placeholder='Password' className="passin" wt={'100%'} onChange={handleChange} required />
            <FormIcon src={show?eyes:eye} alt={show?"hide password":"show password"} onClick={() => {viewPassword("passin"); show? setShow(false):setShow(true)}} />
            </FormMini>
            <FormButton>
                {user.loading?
                    <Spinner
                    color={"white"}
                    loading={true}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                : 'Login'}
            </FormButton>
        </Form>
        <FormText>
            Forgot password?
        </FormText>
   </FormWrapper>
  )
}

export default FormLogin;