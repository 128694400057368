import styled, {keyframes} from "styled-components";

const open = keyframes`
    from {
        top: 0;
        opacity: 0;
    }
    to {
        top: 68px;
        opacity: 1;
    }
`
const open2 = keyframes`
    from {
        top: 0;
        opacity: 0;
    }
    to {
        top: 64px;
        opacity: 1;
    }
`

export const  AlertWrapper = styled.div`
    position: fixed;
    right: 32%;
    width: 30%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({color}) => color? color : '#fd5959'};
    color: white;
    border-radius: 4px;
    border: none;
    padding: 2%;
    z-index: 3;
    animation: ${open} 1s ease;
    @media screen and (max-width: 600px){
        right: 27%;
        width: 44%;
        animation: ${open2} 1s ease;
    }
    @media screen and (max-width: 570px){
        right: 23%;
        width: 50%;
        animation: ${open2} 1s ease;
    }
    @media screen and (max-width: 520px){
        right: 24%;
        animation: ${open2} 1s ease;
    }
    @media screen and (max-width: 501px){
        right: 22%;
        width: 53%;
        animation: ${open2} 1s ease;
    }
    @media screen and (max-width: 475px){
        right: 19%;
        width: 58%;
        animation: ${open2} 1s ease;
    }
    @media screen and (max-width: 432px){
        right: 17%;
        width: 62%;
        animation: ${open2} 1s ease;
    }
    @media screen and (max-width: 414px){
        width: 68%;
        right: 17%;
        height: 30px;
        animation: ${open2} 1s ease;
    }
`
export const AlertText = styled.h1``

export const AlertClose = styled.img`
    height: 20px;
    cursor: pointer;
`