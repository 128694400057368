import React from 'react'
import { ContentWrapper } from './style'
import AccountInfo from '../../components/acct_bar'
import HeaderVerify from '../header'
import profile from '../../assets/images/av1.svg'

const  Content = ({text1, text2, text3, data, dataii, user_data, ficon, ficonii, ficon_alt, color1, color2, classnamei, funci, classnameii, funcii, tcolor}) => {

  return (
    <ContentWrapper>
        {text1?<HeaderVerify text={text1} color={color1} />:<></>}
        <HeaderVerify 
          classes={classnamei} 
          gap={'15%'} 
          ficon={ficon} 
          text={text2} 
          color={color2} 
          col={tcolor}
          ficon_alt={'add new user'} 
          func={() => funci()} 
        />
        {data && 
          data.map((item) => (
            <AccountInfo 
              user_img={item.profile? item.profile.avatar:profile} 
              user_name={item.email}
              user_id={item.id}    
              wt={'100%'} 
              align={true} 
              al={true}
            />
          ))
        }
        {dataii && 
          dataii.map((item, index) => (
            <AccountInfo 
              user_img={item.profile? item.profile.avatar:profile} 
              user_name={item.email}
              user_id={item.id} 
              wt={'100%'} 
              align={true}
              index={index}
              al={true}
              ic 
            />
        ))}
        {text3? 
          <HeaderVerify 
            classes={classnameii} 
            gap={'15%'} 
            ficon={ficonii} 
            text={text3}
            ficon_alt={ficon_alt} 
            color={'transparent'} 
            col={true}
            func={() => funcii()} />
        :
        <></>}
    </ContentWrapper>
  )
}

export default  Content;