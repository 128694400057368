 import styled from 'styled-components'




export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 44%;
    height: 45rem;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
`
