import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader"


export const Button = styled.button`
    background: ${({term}) => term === 'Refute Documents'? 'red': 'rgba(12, 140, 233, 1)' };
    color: white;
    width: ${({wt}) => wt? wt: '49%'};
    height: ${({ht}) => ht? ht: '2.5rem'};
    border: none;
    display: ${({self}) => self? 'flex' : 'block'};
    justify-content: ${({self}) => self? 'space-between' : ''};
    align-self: ${({self}) => self? 'center' : ''};
    padding: ${({self}) => self? '4%' : ''};
    margin: ${({at}) => at};
    cursor: pointer;
    &:hover{
        background: ${({term}) => term === 'Refute Documents'? '#c70a0a': 'rgb(5 88 149)' };
    }
`
export const ButtonIcon = styled.img`
    height: 1.1rem;
    transform: translate(30%, 18%);
`
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`