import React from 'react'
import { TagWrapper, TagIcon } from './style'
import down from '../../assets/icons/down.svg'

const Tag = ({text, color, hide, mg}) => {
  return (
    <TagWrapper color={color} mg={mg}>
        {text}
        {hide? <></>:<TagIcon src={down} alt='dropdown' />}
    </TagWrapper>
  )
}

export default Tag