import styled from 'styled-components';

export const  Container = styled.div`
    display: flex;
    margin: auto;
`
export const Header = styled.h2`
    font-size: 1.4rem;
    font-weight: 700;
`

export const Text = styled.p``

