import React from 'react';
import { Container, Header, Text } from './style';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to trigger rendering of a fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.log('Error caught by error boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Header>Something went wrong.</Header>
          <Text>Please contact the Developer</Text>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
