import styled, {keyframes} from 'styled-components'


const open = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const ModalWrapper = styled.div`
    width: 30%;
    height: ${({ht}) => ht? ht : '22rem'};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    position: absolute;
    top: 5rem;
    right: 26rem;
    z-index: 2;
    background: white;
`

export const BackDrop = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
`

export const HeaderWrapper = styled.div`
    background: #0C8CE9;
    padding: 3%;
    display: flex;
    justify-content: space-between;
    color: white;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
`
export const ExtraWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3%;
    gap: ${({gp}) => gp? '9%':'2%'};
`
export const Text = styled.p`
    &.special-text {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 700;
        width: 70%;
        align-self: center;
    }
`

export const Icon = styled.img`
    height: ${({ht}) => ht};
    cursor: ${({point}) => point? 'pointer':''}
`

export const LogOut = styled.div`
    background: white;
    border-radius: 3px;
    height: auto;
    color: black;
    width: 8%;
    padding: 1%;
    position: absolute;
    top: 4rem;
    right: 4rem;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    animation: ${open} 1s ease;
    &:hover {
        background: #ededed;
        /* rgb(75 157 203) */
    }
`
export const ExtraText = styled.p`
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 15px;
    flex-wrap: wrap;
    align-content: center;
`