import React from 'react'
import { GreetContainer, GreetHeader } from './style'

const Greeting = ({data}) => {
  var d = new Date();
  var time = d.getHours();
  let text;
  let emj;
  if (time < 12) {
    text = 'Good morning'
    emj = '😎'
  }
  if (time > 12) {
    text = 'Good afternoon'
    emj = '😤'
  }
  if (time > 16) {
    text = 'Good evening'
    emj = '😴'
  }
  return (
    <GreetContainer>
        <GreetHeader>
            {text}, {data.first_name + ' ' + data.last_name}{emj}
        </GreetHeader>
    </GreetContainer>
  )
}

export default Greeting