import React from 'react'
import { ExtraText, Icon, LogOut } from '../style'
import logoutxx from '../../../assets/icons/logout.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { signoutRequest } from '../../../features/purge/purge'
import { store } from "../../../app/store";
import { postData } from '../../../features/postSlice/post'


const PopLogOut = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.signin.user)
    const config = {"Content-Type": "application/json", "Authorization": `JWT ${user.tokens.access}`}
    const url = `${process.env.REACT_APP_URL}/user/logout/`
    function signout(){
        dispatch(postData({url: url, data: {'refresh': user.tokens.refresh}, config: config}));
        store.dispatch(signoutRequest());
        navigate('/login');
    }
  return (
    <LogOut>
        <ExtraText onClick={() => signout()}>
            <Icon src={logoutxx} alt='logout' ht='20px' />
            Logout
        </ExtraText>
    </LogOut>
  )
}

export default PopLogOut