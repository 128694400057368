import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



const initialState = {

    loading: false,
    data: [],
    error: '',
    status: '',
    clicked: false,
    googleLog: false,
    extras: {
      name: '',
      role: '',
      page: '',
      address: ''
    }
}
      

export const getData  = createAsyncThunk('data/getData', async (objdata, { rejectWithValue }) => {
  const {url, config} = objdata;
  try {
    const result = await axios.get(url, {headers: config})
    return result.data
  }catch(err){
    return rejectWithValue({status: err.response?.status, data: err.response?.data?.error || err.response?.data, text: err.message})
  }
})
const getSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      click: (state, action) => {
          state.clicked = true
      },
      googCheck : (state, action) => {
        state.googleLog = action.payload
      },
      extras: (state, action) => {
        state.extras.name = action.payload.name
        state.extras.role = action.payload.role
        state.extras.page = action.payload.page
        state.extras.address = action.payload.address
      },
      clear: (state, action) => {
        state.loading = false
        state.data = []
        state.error = ''
      },
      errorClear: (state, action) => {
        state.error = ""
      },
    },
    extraReducers: builder => {
          builder.addCase(getData.pending, state => {
            state.loading = true
            state.data = []
            state.error = ''
          })
          builder.addCase(getData.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
            state.loggedIn = true
            state.clicked = false
          })
          builder.addCase(getData.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.payload.data
            state.status = action.payload.status
            state.success = false
          });
    },
});


export default getSlice.reducer
export const { click, extras, googCheck, clear, errorClear } = getSlice.actions