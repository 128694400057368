import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData, errorClear } from "../features/getSlice/get";
import ChartRow from './charts'
import Greeting from '../layouts/header1'
import { LayoutRow, Spinner, LoadingCont } from './style'
import ListBusiness from '../components/user_list'
import FillerIndex from '../layouts/filler'
import { set_id } from "../features/singleSlice/single";
import { fetchDataHandle, handleRequest } from "../utility";
// import datas from "../data/userdata.json"
import { add } from "../features/getSlice2/get2";
import { store } from "../app/store";
import { postData, setAlert } from "../features/postSlice/post";
import { signoutRequest } from "../features/purge/purge";

const DashboardPage = () => {
  const user = useSelector((state) => state.signin.user);
  const dispatch = useDispatch();
  const url = `${process.env.REACT_APP_BASE_APP_URL}/role/view/unverified/`
  const urli = `${process.env.REACT_APP_BASE_APP_URL}/property/total/?filter=today`
  
  const config = {
    "Content-Type": "application/json", 
    "Authorization": `JWT ${user?.tokens?.access}`
  }
  const data = useSelector((state) => state.get.data);
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  const state = useSelector((state) => state.get);
  const fetch = async () => {
    const request = await fetchDataHandle({url: urli, tokens: user?.tokens?.access});
    const response = handleRequest(request, dispatch, config, user?.tokens)
    if(response === 'success'){
      dispatch(add({chartUser: request.data.users, chartProp: request.data.properties, chartRole: request.data.roles}));
    }
  }

  useEffect(() => {
      dispatch(getData({url: url, config: config})); 
      fetch()
  }, [])
  

  useEffect(()=>{
    if(data.length > 1){
      dispatch(set_id({user_id: data[0].id, role: data[0].role}));
    }
  }, [data])

  useEffect(() => {
    if (state.loading === false && state.error !== ""){
      if(state.status === 400){
          dispatch(setAlert({alert: true, message: state.error}));
          dispatch(errorClear());
        }else if (state.status === 401){
          dispatch(setAlert({alert: true, message: 'Session has expired'}));
          dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
          dispatch(errorClear());
          store.dispatch(signoutRequest());
        }else{
          dispatch(setAlert({alert: true, message: 'Network Error'}));
          dispatch(errorClear());
        }
    }
}, [state.loading])
  // const state = datas.users
  return (
    <>
      {state.loading?
        <LoadingCont>
        <Spinner
       color={"rgba(17, 115, 171, 1)"}
       loading={true}
       size={50}
       aria-label="Loading Spinner"
       data-testid="loader"
       />
       </LoadingCont>
       :
        <>
        <Greeting data={user}/>
        <ChartRow />
        <LayoutRow>
            <ListBusiness data={state && state.data} show />
            <FillerIndex />
        </LayoutRow>
        </>
      }
    </>
  )
}

export default DashboardPage