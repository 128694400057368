import styled, { createGlobalStyle } from 'styled-components'


const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-size: 14px;
        font-family: 'Nunito Sans', sans-serif;
    }
    @media screen and (max-width: 1167px){
        *{  
            font-size: 12px;
         }
    }
    @media screen and (max-width: 1024px){
        *{  
            font-size: 9px;
         }
    }


`



export default GlobalStyle;