import React from 'react'
import { FormIcon, FormInput, FormMini, FormWrapper } from './style'
import lock from '../../assets/icons/lock.svg'

const AddUser = ({hide, func}) => {
  return (
    <>
        <FormWrapper>
            {!hide?
            <FormMini>
              <FormInput type='email' name="email" placeholder='Email' onChange={(event) => func(event)} required/>
            </FormMini>
            :
            <></>}
            <FormMini>
                <FormInput type='password' name="password" placeholder='Password' onChange={(event) => func(event)} required/>
                <FormIcon src={lock} alt='password' />
            </FormMini>
            <FormMini>
                <FormInput type='password' name="password2" placeholder='Repeat Password' onChange={(event) => func(event)} required />
                <FormIcon src={lock} alt='repeat password' />
            </FormMini>
        </FormWrapper>
    </>
  )
}

export default AddUser