import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { postData, setAlert, errorClear, successClear } from "../../features/postSlice/post";
import { BackLink, Form, FormButton, FormFailed, FormInput, FormLogo, FormMini, FormText, FormWrapper, Spinner } from './style'
import logo from '../../assets/images/loginlogo.svg';
import { cook } from '../../features/signinSlice/signin';

const LoginOTP = () => {
    const[check, setCheck] = useState(false);
    const [loginInfo, setLoginInfo] = useState(
        {otp: ""}
        )
    const url = `${process.env.REACT_APP_BASE_APP_URL}/user/worker/login/verify/`
    const config = { "Content-Type": "application/json" }
    const user = useSelector((state) => state.signin);
    const status = useSelector((state) => state.post);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = (event) => {
      event.preventDefault();
      dispatch(postData({url: url, data: loginInfo, config: config}));
    }
    
    useEffect(() => {
      if (status.loading === false && status.error !== ""){
        if(status.status === 400){
            setCheck(true);
          // dispatch(setAlert({alert: true, message: status.error}));
            dispatch(errorClear());
            document.getElementById("otp").disabled = false;
          }else{
            dispatch(setAlert({alert: true, message: 'Network Error'}));
            dispatch(errorClear());
            document.getElementById("otp").disabled = false;
          }
      }else if (status.loading === false && status.error === "" && status.success === true){
        dispatch(cook(status.data));
        dispatch(successClear());
        navigate("/");
      }
    }, [status.loading])
    const handleChange =  (event) => {
        setCheck(false);
        const target = event.target;
        setLoginInfo({
            ...loginInfo, otp: target.value
        });
    }
    useEffect(() => {
      if(loginInfo.otp.length === 6){
        document.getElementById("otp_btn").click();
        document.getElementById("otp").disabled = true;
      }
    }, [loginInfo])
return (
   <FormWrapper>
        <FormLogo src={logo} alt="Pinearth" />
        <Form onSubmit={handleSubmit}>
            <FormInput type='number' warn={check} name='otp' id='otp' placeholder='verifyxxx' onChange={handleChange} required />
            {check? <FormFailed>Invalid OTP</FormFailed>: <></>}
            <FormButton id='otp_btn' type='submit'>
                {user.loading?
                    <Spinner
                    color={"white"}
                    loading={true}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                : 'verify'}
            </FormButton>
            <BackLink to="/login">Resend link</BackLink>
        </Form>
   </FormWrapper>
  )
}

export default LoginOTP;