import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'



const initialState = {

  loading: false,
  singledata: [],
  user_id: 0,
  twitch: false,
  role: '',
  error: '',
  status: '',
  success: false,
  activeIndex: null
}


export const singleData  = createAsyncThunk('data/singleData', async (objdata, { rejectWithValue }) => {
  const {url, config} = objdata;
  try {
    const result = await axios.get(url, {headers: config})
    return result.data
  }catch(err){
    return rejectWithValue({status: err.response?.status, data: err.response?.data?.error || err.response?.data, text: err.message})
  }
})
const singleSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      set_id: (state, action) => {
        state.user_id =  action.payload.user_id
        state.role = action.payload.role
      },
      menu: (state, action) => {
        state.twitch = action.payload
      },
      set_index: (state, action) => {
        state.activeIndex = action.payload
      },
      errorClear: (state, action) => {
          state.error = ""
      },
      successClear: (state, action) => {
        state.success = false
      }
    },
    extraReducers: builder => {
          builder.addCase(singleData.pending, state => {
            state.singledata = []
            state.loading = true
            state.error = ''
            state.success = false
          })
          builder.addCase(singleData.fulfilled, (state, action) => {
            state.loading = false
            state.singledata = action.payload
            state.error = ''
            state.success = true
          })
          builder.addCase(singleData.rejected, (state, action) => {
            state.loading = false
            state.singledata = []
            state.error = action.payload.data
            state.status = action.payload.status
            state.success = false
          });
    },
});


export default singleSlice.reducer
export const { set_id, menu, set_index, errorClear, successClear } = singleSlice.actions