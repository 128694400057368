import dashboard from '../assets/icons/dashboard.svg'
import key from '../assets/icons/key.svg'
import horn from '../assets/icons/horn.svg'
import setting from '../assets/icons/setting.svg'
import bdashboard from '../assets/icons/bdashboard.svg'
import bkey from '../assets/icons/bkey.svg'
import bhorn from '../assets/icons/bhorn.svg'
import bsetting from '../assets/icons/bsetting.svg'

export const MenuData = [
    {
        text: 'Dashboard',
        icon: dashboard,
        bicon:bdashboard,
        link: '/'
    },
    {
        text: 'Verification',
        icon: key,
        bicon:bkey,
        link: '/verification'
    },
    {
        text: 'Annoucements',
        icon: horn,
        bicon:bhorn,
        link: '/annoucements'
    },
    {
        text: 'Settings',
        icon: setting,
        bicon:bsetting,
        link: '/settings'
    },
    
]

export const Data = {
    "users": {
        "current": 100,
        "total": 10000
    },
    "properties": {
        "current": 10,
        "total": 10000
    },
    "roles": {
        "current": 100,
        "total": 10000
    }        
}