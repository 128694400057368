import React from 'react'
import { HeaderContent, HeaderIcon, HeaderWrapper } from './style'

const HeaderVerify = ({color, col, ficon, gap, ficon_alt, text, sicon, sicon_alt, classes, func}) => {
  return (
    <HeaderWrapper className={classes} onClick={() => func()} color={color} gp={gap} col={col}>
        {ficon? <HeaderIcon src={ficon} alt={ficon_alt} />:<></>}
        <HeaderContent>
            {text}
        </HeaderContent>
        <HeaderIcon src={sicon} alt={sicon_alt} />
    </HeaderWrapper>
  )
}

export default HeaderVerify