import React, {useEffect} from 'react'
import { LayoutColumn, LayoutRow } from './style'
import Menu from '../components/menu'
import Bar from '../components/nav'
import ShowAlert from "../components/alert";
import { setAlert } from '../features/postSlice/post';
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const BaseLayout = () => {
  const state = useSelector((state) => state.single.twitch);
  const alert = useSelector((state) => state.post);
  const dispatch = useDispatch();

  useEffect(() => {
    if(alert.alert){
        setTimeout(() => {
            dispatch(setAlert({alert: false, message: "", color: ""}))
        }, 5000)
    }
  }, [alert.alert])
  return (
    <LayoutRow>
        {state && <Menu />}
        <LayoutColumn>
            <Bar />
            {alert.alert && <ShowAlert text={alert.alertMessage} color={alert.alertColor} />}
            <Outlet />
        </LayoutColumn>
    </LayoutRow>
  )
}

export default BaseLayout