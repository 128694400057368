import React from 'react'
import { ChartDetailsWrapper, ChartHeader } from './style'
import DownTag from '../tags/tagdown'
import Tag from '../tags'
import DoughnutChart from '../chart'

const ICharts = ({title, stext, type, color, total, current}) => {
  return (
    <ChartDetailsWrapper>
        <ChartHeader>
            {title}
        </ChartHeader>
        <DownTag type={type} />
        {stext? <Tag text={stext} />:<></>}
        <DoughnutChart ccolor={color} title={title} total={total} current={current}/>
    </ChartDetailsWrapper>
  )
}

export default ICharts