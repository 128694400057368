import React from 'react'
import { Button, ButtonIcon, Spinner } from './style'

const ExButton = ({loading, term, text, icon, func, iconalt, self, ht, at, wt}) => {
  return (
    <Button term={term} onClick={(event) => func(event)} self={self} ht={ht} at={at} wt={wt}>
        {loading?
        <Spinner
        color={"white"}
        loading={true}
        size={25}
        aria-label="Loading Spinner"
        data-testid="loader"
        />
        : text}
        {icon?<ButtonIcon src={icon} alt={iconalt} />:<></>}
    </Button>
  )
}

export default ExButton