import { useState, useEffect } from 'react'
import { LayoutRow, LoadingCont, Spinner } from './style'
import Content from '../layouts/content'
import lock from '../assets/icons/lock.svg'
import block from '../assets/icons/block.svg'
import badd from '../assets/icons/buserplus.svg'
import add from '../assets/icons/userplus.svg' 
// import data from '../data/userdata.json'
import { PopUpCheck, PopUpDone, PopUpEdit, PopUpMisc } from '../components/popup'
import { useSelector, useDispatch } from 'react-redux'
import { click } from '../features/registerSlice/rdataSlice'
import { getData, errorClear } from '../features/getSlice/get'
import { store } from "../app/store";
import { postData, setAlert } from "../features/postSlice/post";
import { signoutRequest } from "../features/purge/purge";

const SettingPage = () => {
  const status = useSelector((state) => state.data.controls);
  const check = useSelector((state) => state.post);
  const [active, setActive] = useState(1);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.signin.user);
  const url = `${process.env.REACT_APP_BASE_APP_URL}/user/workers/all/`;
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  
  const config = {
      "Content-Type": "application/json", 
      "Authorization": `JWT ${user.tokens.access}`
  };

  useEffect(()=>{
      dispatch(getData({url: url, config: config}));
  },[]);

  useEffect(()=>{
    if(check.success){
      dispatch(getData({url: url, config: config}));
    };
  },[check]);

  const state = useSelector((state) => state.get);
  function addUser(){
    dispatch(click({adduser: true}));
  };

  useEffect(() => {
    if (state.loading === false && state.error !== ""){
      if(state.status === 400){
          dispatch(setAlert({alert: true, message: state.error}));
          dispatch(errorClear());
        }else if (state.status === 401){
          dispatch(setAlert({alert: true, message: 'Session has expired'}));
          dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
          store.dispatch(signoutRequest());
        }else{
          dispatch(setAlert({alert: true, message: 'Network Error'}));
          dispatch(errorClear());
        }
    }
}, [state.loading])

  return (
    <>
    {status?.adduser? <PopUpCheck /> : <></>}
    {status?.create? <PopUpMisc /> : <></>}
    {status?.changepass? <PopUpEdit />: <></>}
    {status?.done? <PopUpDone text={"Done"} /> : <></>}
    {state.loading?
      <LoadingCont>
      <Spinner
        color={"rgba(17, 115, 171, 1)"}
        loading={true}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
     </LoadingCont>
     :
     <LayoutRow top>
        <Content 
            text1={'Settings'}
            text2={'Add new user'}
            text3={'Change password'}
            color1={'black'}
            tcolor={true}
            ficon={active === 0? add:badd}
            ficonii={active === 1? lock:block}
            ficon_alt={'password change'}
            classnamei={active === 0? 'select': 'deselect'}
            funci={() => setActive(0)}
            classnameii={active === 1? 'select': 'deselect'}
            funcii={() => setActive(1)}
        />
        {active === 0?
        <Content 
          text2={'Add new user'}
          text3={'Add new user'}
          color2={'black'}
          data={state.data}
          ficon={add}
          ficonii={badd}
          ficon_alt={'add user'}
          funcii={() => addUser()}
          user_data={user}
        />
        :
        <Content 
            ficon={add}
            text2={'Select user'}
            color2={'black'}
            dataii={state.data}
            user_data={user}
        />
        }
    </LayoutRow>}
    </>
  )
}

export default SettingPage;