import React from 'react'
import { MenuIcon, MenuLink, MenuText, MenuWrapper } from './style'
import { MenuData } from '../../data/menudata'
import { useDispatch } from 'react-redux'
import { set_index } from '../../features/singleSlice/single'
import { useLocation } from 'react-router-dom'

const Menu = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const handleClick = (indexed) => {
        dispatch(set_index(indexed));
    }     
  return (
    <MenuWrapper>
        {MenuData && MenuData.map((item, index) => (
                <MenuLink key={index}
                          to={item.link}
                          className={({ isActive }) => isActive? handleClick(index): ''}
                >
                    <MenuIcon src={location.pathname === item.link ? item.bicon : item.icon} alt={item.text} />
                    <MenuText>{item.text}</MenuText>
                </MenuLink>
            ))
        }
    </MenuWrapper>
  )
}

export default Menu