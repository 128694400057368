import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader"



export const Row = styled.section`
    display: flex;
    width: 100%;
    gap: 1%;
    padding: 1%;
`

export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`

export const LoadingCont = styled.section`
    height: 200px;
    display: grid;
`

export const LoadingCont2 = styled.section`
    height: 379px;
    width: 32%;
    display: grid;
`