import React from 'react'
import { Filler, FillerContainer, FillerHeader } from './style'
import filler from '../../assets/images/refresh.svg'

const FillerIndex = ({text, image}) => {
  return (
    <FillerContainer>
        <FillerHeader>
            Click a profile to get started
        </FillerHeader>
        <Filler src={filler} alt='click to get started' />
    </FillerContainer>
  )
}

export default FillerIndex