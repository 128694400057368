import styled from "styled-components";


export const FormWrapper = styled.form`
    width: 100%;
    padding-top: ${({pt}) => pt};
`
export const Center = styled.div`
    display: block;
    position: relative;
    height: 20px;
    width: 20px;
`
export const FormInput = styled.input`
    border: none;
    width: ${({ht}) => ht? ht : '100%'};
    padding: 2%; 
    padding-left: 4%;
    /* &.specifyColor {
        accent-color: #38EDA1;
    } */
    &.specifyColor { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        /* Your custom styles here */
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #38EDA1;
        background-color: #fff;
        outline: none;
        &::after {
                content: '';
                display: none;
                position: absolute;
                top: 3px;
                left: 3px;
                /* transform: translate(-43%, -43%); */
                height: 13px;
                width: 13px;
                border-radius: 50%;
                align-self: center;
                background-color: #38EDA1;
        } 

        &:checked {
            border: 2px solid #38EDA1;
            &.specifyColor::after{
                display: block;
            }
        }
    } 
    &:focus {
        outline: none;
        &::placeholder{
            font-size: 0.8rem;
        }
    } 
`

export const FormMini = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    border-bottom: ${({bottom}) => bottom? 'none': '1px solid #00000033'};
`
export const MiniPad = styled(FormMini)`
    padding-left: 9%;
    padding-bottom: 2%;
`
export const FormIcon = styled.img`
    height: 20px;
    cursor: pointer;
    align-self: center;
`

export const FormLabel = styled.label`
    width: 90%;
`

export const LabelWrap = styled.span`
`