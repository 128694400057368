import styled from 'styled-components'

export const AccountWrapper = styled.div`
    display: flex;
    width: ${({wt}) => wt? wt:'16%'};
    height: ${({cont}) => cont? '4rem': ''};
    padding: ${({cont}) => cont? '5%': ''};
    flex-wrap: ${({cont}) => cont? 'wrap':''};
    align-content: ${({cont}) => cont? 'center':''};
    align-self: ${({center}) => center};
    gap: 16%;
    background: ${({color}) => color? color:'transparent'};
    border-bottom: ${({cont}) => cont? '1px solid rgba(217, 217, 217, 1)':'none'};
    cursor: pointer;
    &.base {
        color: black;
        background: transparent;
    }
`
export const AcctProfile = styled.img`
    border-radius: 999px;
    height: 2.5rem;
    width: 2.5rem;
`
export const AcctText = styled.p`
    color: ${({tcolor}) => tcolor};
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    /* margin-left: ${({cont}) => cont? '-13%':''}; */
    text-align: left;
    width: ${({al}) => al? '30%':''};
`

export const AcctIcon = styled.img`
    height: 2rem;
    margin-top: 0.5%;
`