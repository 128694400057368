import React, { useEffect, useState } from 'react'
import { BackDrop, ExtraWrapper, HeaderWrapper, ModalWrapper, Text, Icon } from '../style'
import AddUser from '../../form'
import ExButton from '../../button'
import cross from '../../../assets/icons/cross.svg'
import AccountInfo from '../../acct_bar'
import { useDispatch, useSelector } from 'react-redux'
import { click } from '../../../features/registerSlice/rdataSlice'
import { putData, errorClear, successClear } from '../../../features/putSlice/put'
import { postData, setAlert } from '../../../features/postSlice/post'
import { signoutRequest } from '../../../features/purge/purge'
import { store } from '../../../app/store'

const PopUpEdit = () => {
  const user = useSelector((state) => state.signin.user)
  const request = useSelector((state) => state.put.data)
  const loading = useSelector((state) => state.put.loading)
  const user_data = useSelector((state) => state.data.controls)
  const dispatch = useDispatch();
  function close(){
    dispatch(click({changepass: false}));
  }
  const [data, setData] = useState({
    password: '',
    password2: ''
  });
  const url = `${process.env.REACT_APP_BASE_APP_URL}/user/edit/${user_data.id}/`
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  const config = {
    'content-type': 'application/json', 
    'Authorization': `JWT ${user.tokens.access}`
  }
  const handleChange = (event) => {
    const target = event.target
    setData({
      ...data,
      [target.name]: target.value
    })
  }
  const handleSubmit = () => {
    dispatch(putData({url:url, config:config, data:data}))
  }
  useEffect(() => {
      if (request.loading === false && request.error !== ""){
        if(request.status === 400){
            dispatch(setAlert({alert: true, message: request.error}));
            dispatch(errorClear());
          }else if (request.status === 401){
            dispatch(setAlert({alert: true, message: 'Session has expired'}));
            dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
            dispatch(errorClear());
            store.dispatch(signoutRequest());
          }else{
            dispatch(setAlert({alert: true, message: 'Network Error'}));
            dispatch(errorClear());
          }
      }else if (request.loading === false && request.error === "" && request.success === true){
          dispatch(click({changepass: false, done: true}));
          dispatch(successClear());
      }
  }, [request.success, request.error])
  return (
    <>
    <BackDrop>
    </BackDrop>
    <ModalWrapper ht={'25rem'}>
      <HeaderWrapper>
          <Text>Edit password</Text>
          <Icon src={cross} alt='close' onClick={() => close()}/>
      </HeaderWrapper>
        <AccountInfo
                user_name={user_data.user_name} 
                user_img={user_data.profile}
                wt={'57%'}
                center={'center'}
        />
        <ExtraWrapper gp>
            <AddUser hide={true} func={() => handleChange()} />
            <ExButton text={'Cancel'} func={() => close()} term={'Refute Documents'} ht={'3rem'} wt={'41%'} />
            <ExButton text={'Set password'} loading={loading} func={() => handleSubmit()} ht={'3rem'} wt={'41%'} />
        </ExtraWrapper>
    </ModalWrapper>
    </>
  )
}

export default PopUpEdit;