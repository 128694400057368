import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";


export const FormWrapper = styled.form`
    width: 27%;
    margin: auto;
    text-align: left;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    @media screen and (max-width: 600px){
        width: 50%;   
    }
    @media screen and (max-width: 300px){
    width: 65%;
}
`


export const FormHeader = styled.h1`
    font-size: 1.8rem;
    margin-top: 2rem;
    text-align: center;
    margin-bottom: ${({reg}) => reg? '8rem' : ''};
    padding: ${({reg}) => reg? '8%' : ''};
    border-bottom: ${({reg}) => reg? '1px solid rgba(17, 115, 171, 1)' : '' };
`



export const FormButton = styled.button`

    border: none;
    border-radius: 6px;
    height: 48px;
    width: ${({wt}) => wt? wt : "35%"};
    color: white;
    cursor: pointer;
    text-align: center;
    background: ${props => props.left? "rgba(0, 0, 0, 0.5)": "rgba(17, 115, 171, 1)"};
    align-self: ${({er}) => er?  "center" : "" };
`
export const ListedImg = styled.img`
    width: ${({er}) => er? "50%" : "30%"};
    height: ${({er}) => er?  "21rem" : "4rem" };
    align-self: ${({er}) => er?  "center" : "" };
    border-radius: 7px;
`
