import { SelectWrapper, Option } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataHandle } from '../../utility'
import { add } from '../../features/getSlice2/get2'
 
const DownTag = ({type}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.signin.user);
    const setFilter = async (event) => {
        try {
          const param = event.target.value;
      
          const url = param === 'all'
            ? `${process.env.REACT_APP_BASE_APP_URL}/property/total/`
            : `${process.env.REACT_APP_BASE_APP_URL}/property/total/?filter=${param}`;
      
          const { data } = await fetchDataHandle({
            url: url,
            tokens: user.tokens.access
          });
      
          switch (type) {
            case 'chartUser':
              dispatch(add({ chartUser: data.users }));
              break;
            case 'chartProp':
              dispatch(add({ chartProp: data.properties }));
              break;
            case 'chartRole':
              dispatch(add({ chartRole: data.roles }));
              break;
            default:
              dispatch(add({ chartUser: data.users, chartProp: data.properties, chartRole: data.roles }));
          }
        } catch (error) {
          console.error("Error in setFilter:", error);
          // Handle errors here
        }
      };
  return (
    <SelectWrapper onChange={(event) => setFilter(event)} defaultValue={'today'}>
        <Option value='today' >Today</Option>
        <Option value='yesterday'>Yesterday</Option>
        <Option value='month'>1 Month</Option>
        <Option value='year'>1 Year</Option>
        <Option value='all'>All</Option>
    </SelectWrapper>
  )
}

export default DownTag;