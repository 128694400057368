import React, { useEffect, useState } from 'react'
import { putData, errorClear, successClear } from "../../../features/putSlice/put";
import { useDispatch, useSelector } from 'react-redux';
import { UserButtonWrap, UserContainer, UserImage, UserLabel, UserProfile, UserText, Spinner } from '../style'
import ExButton from '../../button'
import cross from '../../../assets/icons/cross.svg'
import checked from '../../../assets/icons/checked.svg'
import { view } from '../../../features/registerSlice/rdataSlice';
import { store } from '../../../app/store';
import { postData, setAlert } from '../../../features/postSlice/post';
import { signoutRequest } from '../../../features/purge/purge';

const Business = ({data}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.signin.user);
  const loading = useSelector((state) => state.put.loading);
  const status = useSelector((state) => state.put);
  const state = useSelector((state) => state.single);
  const [loaders, setLoaders] = useState({
    refuteload: false,
    verifyload: false
  })
  const config = {
    "Content-Type": "application/json",
    "Authorization": `JWT ${user?.tokens?.access}`
  }
  function Open(src){
    dispatch(view({open: true, data: src}));
  }
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  function refute(id, role){
    const url2 = `${process.env.REACT_APP_BASE_APP_URL}/role/refute/${role}/${id}/`
    dispatch(putData({url: url2, config: config}));
    setLoaders({
      ...loaders,
      refuteload: loading
    })
  }
  function verify(id, role){
    const url2 = `${process.env.REACT_APP_BASE_APP_URL}/role/verify/${role}/${id}/`
    dispatch(putData({url: url2, config: config}));
    setLoaders({
      ...loaders,
      refuteload: loading
    })
  }
  useEffect(() => {
    if(!loading){
      setLoaders({
        refuteload: false,
        verifyload: false
      })
    }
    if (status.loading === false && status.error !== ""){
      if(status.status === 400){
          dispatch(setAlert({alert: true, message: status.error}));
          dispatch(errorClear());
        }else if (status.status === 401){
          dispatch(setAlert({alert: true, message: 'Session has expired'}));
          dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
          dispatch(errorClear());
          store.dispatch(signoutRequest());
        }else{
          dispatch(setAlert({alert: true, message: 'Network Error'}));
          dispatch(errorClear());
        }
    }else if (status.loading === false && status.error === "" && status.success === true){
        dispatch(successClear());
    }
  }, [loading])
  return (
    <UserContainer wt='21%'>
        {state.loading?
          <Spinner
          color={"rgba(17, 115, 171, 1)"}
          loading={true}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
          />
          :
          <>
          <UserLabel>User Details</UserLabel>
        <UserImage src={data.profile_photo} alt={data.company_name} onClick={() => Open(data.profile_photo)} />
        <UserButtonWrap gap>
            <UserText color='rgba(25, 106, 181, 1)' decor='underline' >{data.email}</UserText>
            <UserText color='rgba(0, 0, 0, 0.5)'>Name: {data.company_name}</UserText>
            <UserText color='rgba(0, 0, 0, 0.5)'>ID: {'passport'}</UserText>
            <UserText color='rgba(0, 0, 0, 0.5)'>Created: {data.created_at}</UserText>
        </UserButtonWrap>
        <UserButtonWrap gap>
        <UserLabel>ID</UserLabel>
        <UserText>
          *image must be clear
        </UserText>
        </UserButtonWrap>
        <UserProfile src={data.profile_photo} alt={data.company_name} onClick={() => Open(data.profile_photo)} />
        <ExButton text={'Verify this user'} self wt={'80%'} loading={loaders.verifyload} icon={checked} func={() => verify(data.id, data.role)} iconalt={'verify'} />
        <ExButton text={'Refute Documents'} term={'Refute Documents'} self wt={'80%'} loading={loaders.refuteload} func={() => refute(data.id, data.role)} icon={cross} iconalt={'refute'} />
          </>
        }
    </UserContainer>
  )
}

export default Business