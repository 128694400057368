import React, { useEffect, useState } from 'react'
import { UserContainer, UserButton, UserButtonWrap, UserInput, UserLabel, UserProfile, UserText, Spinner } from '../style'
import { set_id } from "../../../features/singleSlice/single";
import { errorClear, successClear } from "../../../features/putSlice/put";
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../app/store';
import { postData, setAlert } from '../../../features/postSlice/post';
import { view } from '../../../features/registerSlice/rdataSlice';
import { signoutRequest } from '../../../features/purge/purge';


const Profile = ({data, top}) => {
  const[currentIndex, setCurrenIndex] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.signin.user);
  const list_ids = useSelector((state) => state.get.data);
  const state = useSelector((state) => state.single);
  const reload = useSelector((state) => state.put);
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  
  function next(){
    let index = currentIndex+1
    if(index > list_ids.length){
      setCurrenIndex(0);
      dispatch(set_id({user_id: list_ids[0].id, role: list_ids[currentIndex].role}));
    } else{
      setCurrenIndex(index);
      dispatch(set_id({user_id: list_ids[index].id, role: list_ids[index].role}));
    }
  }
  function back(){
    let index = currentIndex - 1
    if(index < 0){
      setCurrenIndex(list_ids.length-1);
      dispatch(set_id({user_id: list_ids[list_ids.length-1].id, role: list_ids[list_ids.length-1].role}));
    } else{
      setCurrenIndex(index);
      dispatch(set_id({user_id: list_ids[index].id, role: list_ids[index].role}));
    }
  }
  function Open(src){
    dispatch(view({open: true, data: src}))
  }
  useEffect(() => {
    for(let i = 0; i<list_ids.length; i++){
      if(list_ids[i].id === data.id && list_ids[i].role === data.role){
        setCurrenIndex(i);
      }
    }
  }, [data.id])
  useEffect(() => {
    if (reload.loading === false && reload.error !== ""){
      if(reload.status === 400){
          dispatch(setAlert({alert: true, message: reload.error}));
          dispatch(errorClear());
        }else if (reload.status === 401){
          dispatch(setAlert({alert: true, message: 'Session has expired'}));
          dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
          dispatch(errorClear());
          store.dispatch(signoutRequest());
        }else{
          dispatch(setAlert({alert: true, message: 'Network Error'}));
          dispatch(errorClear());
        }
    }else if (reload.loading === false && reload.error === "" && reload.success === true){
      next();  
      dispatch(successClear());
    }
  }, [reload.loading])
  return (
    <UserContainer top={top}>
        {state.loading?
          <Spinner
          color={"rgba(17, 115, 171, 1)"}
          loading={true}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
          />
          :
          <>
          <UserLabel>Professional profile photo</UserLabel>
        <UserText>*image must be clear</UserText>
        <UserProfile src={data.profile_photo} alt={data.company_name} onClick={() => Open(data.profile_photo)} />
        <UserLabel>About Yourself</UserLabel>
        <UserText>*(What Makes You Different As An Agent)</UserText>
        <UserInput type='text' readOnly value={data.about_you} />
        <UserLabel>Specialties</UserLabel>
        <UserInput type='text' readOnly value={data.specialties} />
        <UserButtonWrap>
          <UserButton color='gray' onClick={() => back()}>
            Back
          </UserButton>
          <UserButton onClick={() => next()}>
            Next
          </UserButton>
        </UserButtonWrap>
          </>
        }
    </UserContainer>
  )
}

export default Profile