import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


import postReducer from '../features/postSlice/post'
import putReducer from '../features/putSlice/put'
import dataReducer from '../features/registerSlice/rdataSlice'
import signinReducer from '../features/signinSlice/signin'
import singleReducer from '../features/singleSlice/single'
import getReducer from '../features/getSlice/get'
import getReducer2 from '../features/getSlice2/get2'
import { SIGNOUT_REQUEST } from "../features/purge/purge";


const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['data', 'put'],
    stateReconciler: autoMergeLevel2 
  }



  const appReducer = combineReducers({ 
    post: postReducer,
    put: putReducer,
    data: dataReducer,
    signin: signinReducer,
    single: singleReducer,
    get: getReducer,
    get2: getReducer2
  })

  // const SIGNOUT = {
  //   type: SIGNOUT_REQUEST
  // }

  const rootReducer = (state, action) => {
    if (action.type === SIGNOUT_REQUEST) {
        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root')
        // storage.removeItem('persist:otherKey')

        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
  
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  
 export const store = configureStore({
    reducer: persistedReducer
  })
  
  export const persistor = persistStore(store)
