import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { ChartContainer, ChartText, ChartTotal, ChartWrapper } from "./style";
import { formatNumber } from "../../utility";

const DoughnutChart = ({ccolor, title, total, current, loading}) => {
    var chartData = {
        labels:[ccolor, 'white'],
        datasets: [{
            label: title,
            data: [current, total],
            backgroundColor: [ccolor, 'white'],
            enabled: false,
            rotation: 90
        }]
    }
  
    return (
        <ChartWrapper>
            <ChartTotal>
                <ChartText>
                    {formatNumber(current)}
                </ChartText>
            </ChartTotal>
            <ChartContainer>
                <Doughnut
                        data={chartData}
                        options={{
                        responsive: true,
                        plugins: {
                            legend:{
                                display: false,
                            },
                            title: {
                                display: false,
                            }
                            }
                        }}
                />
            </ChartContainer>
        </ChartWrapper>
    )
  }

  export default DoughnutChart;