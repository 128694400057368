import { useState, useEffect} from 'react'
import { BackDrop, ExtraWrapper, HeaderWrapper, Icon, ModalWrapper, Text } from '../style'
import cross from '../../../assets/icons/cross.svg'
import AddUser from '../../form'
import ExButton from '../../button'
import { useDispatch, useSelector } from 'react-redux'
import { click } from '../../../features/registerSlice/rdataSlice'
import { postData, setAlert, errorClear, successClear } from '../../../features/postSlice/post'
import { signoutRequest } from '../../../features/purge/purge'
import { store } from '../../../app/store'


const PopUpMisc = () => {
  const user = useSelector((state) => state.signin.user);
  const request = useSelector((state) => state.post);
  const role = useSelector((state) => state.data.controls.role);
  const dispatch = useDispatch();
  function close(){
    dispatch(click({create: false}));
  }
  const [data, setData] = useState({
    email: '',
    password: '',
    password2: ''
  });
  const url = `${process.env.REACT_APP_BASE_APP_URL}/role/${role}/create/`
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  const config = {
    'content-type': 'application/json', 
    'Authorization': `JWT ${user.tokens.access}`
  }
  const handleChange = (event) => {
    const target = event.target;
    setData({
      ...data,
      [target.name]: target.value
    })
  }
  const handleSubmit = () => {
    dispatch(postData({url:url, config:config, data:data}))
  }
  useEffect(() => {
    if (request.loading === false && request.error !== ""){
      if(request.status === 400){
          dispatch(setAlert({alert: true, message: request.error}));
          dispatch(errorClear());
        }else if (request.status === 401){
          dispatch(setAlert({alert: true, message: 'Session has expired'}));
          dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
          dispatch(errorClear());
          store.dispatch(signoutRequest());
        }else{
          dispatch(setAlert({alert: true, message: 'Network Error'}));
          dispatch(errorClear());
        }
    }else if (request.loading === false && request.error === "" && request.success === true){
      dispatch(click({create: false, done: true}));
      dispatch(successClear());
    }
  }, [request.success, request.error])
  return (
    <>
    <BackDrop>
    </BackDrop>
    <ModalWrapper>
        <HeaderWrapper>
            <Text>
              Add new account ({role})
            </Text>
            <Icon src={cross} alt='close' point onClick={() => close()} />
        </HeaderWrapper>
        <ExtraWrapper>
            <AddUser func={() => handleChange()}/>
            <ExButton text={'Cancel'} func={() => close()} term={'Refute Documents'} ht={'3rem'}/>
            <ExButton text={'Finish'} loading={request.loading} func={() => handleSubmit()} ht={'3rem'}/>
        </ExtraWrapper>
    </ModalWrapper>
    </>
  )
}

export default PopUpMisc