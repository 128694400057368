import { createSlice } from "@reduxjs/toolkit";




const initialState = {
    controls: {
        adduser: false,
        changepass: false,
        create: false,
        done: false,
        render: false,
        role: '',
        id: 0,
        user_name: '',
        profile: '',
        logout: false
    },
    open: false,
    data: "",
}



const rdataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        click: (state, action) => {
            state.controls = {
                ...state,
            ...action.payload
            };
        },
        view: (state, action) => {
            state.open = action.payload.open
            state.data = action.payload.data
        }
    }
});

export default rdataSlice.reducer
export const { click, view } = rdataSlice.actions