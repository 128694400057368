import React from 'react'
import { Row, Spinner, LoadingCont2 } from './style'
import ICharts from '../../components/chart_details'
import { useSelector } from 'react-redux'
import { Data } from "../../data/menudata"

const ChartRow = () => {
  const chartUser = useSelector((state) => state.get2.chartData.chartUser)
  const chartProp = useSelector((state) => state.get2.chartData.chartProp)
  const chartRole = useSelector((state) => state.get2.chartData.chartRole)
  return (
    <Row>
        {
         chartUser?
          (<ICharts 
            title={'Total users'}
            type={'chartUser'}
            color={'rgba(223, 191, 25, 1)'}
            total={chartUser.total}
            current={chartUser.current}
            />)
            :
          (<LoadingCont2>
            <Spinner
            color={"rgba(12, 140, 233, 1)"}
            loading={true}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
         </LoadingCont2>)
        }

        {
          chartProp?
          (<ICharts  
            title={'Listed properties'}
            type={'chartProp'}
            color={'rgba(223, 25, 25, 1)'}
            total={chartProp.total}
            current={chartProp.current}
            />)
            :
            (<LoadingCont2>
              <Spinner
               color={"rgba(12, 140, 233, 1)"}
               loading={true}
               size={25}
               aria-label="Loading Spinner"
               data-testid="loader"
             />
            </LoadingCont2>)                      
        }
          
        {
          chartRole?
          (<ICharts 
            title={'Contacts'}
            type={'chartRole'}
            stext={'Login'}
            color={'rgba(1, 221, 66, 1)'}
            total={chartRole.total}
            current={chartRole.current}
          />)
          :
          (<LoadingCont2>
             <Spinner
               color={"rgba(12, 140, 233, 1)"}
               loading={true}
               size={25}
               aria-label="Loading Spinner"
               data-testid="loader"
             />
          </LoadingCont2>)
        }
    </Row>
  )
}

export default ChartRow