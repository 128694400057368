import { Close, MainImg, MainWrap, Swipe, ThumbNail, ThumbNailWrap, Wrapper } from "./style";
import { useEffect, useRef, useState } from "react";
import close from "../../assets/icons/closew.svg"
import nextbtn from "../../assets/icons/nextwh.svg"
import prevbtn from "../../assets/icons/prevwh.svg"
import { view } from "../../features/registerSlice/rdataSlice";
import { useDispatch } from "react-redux";


const ViewGallery = ({data}) => {
    const[value, setValue] = useState(0)
    const dsrc= data
    // const dsrc= data[value]
    const thmbRef = useRef(null)
    const dispatch = useDispatch();
    const thb = Array.from(document.getElementsByClassName('thumbnails'));
    function clear(){
            thb.forEach((thumb) => thumb.classList.remove('active'));
    }
    // const reloadImg = (event) => {
    //     const target = event.target;
    //     const newIndex = parseInt(target.getAttribute('data-key'));
    //     setValue(newIndex);
    //     console.log(value)
    //     clear();
    //     thb[newIndex].classList.add('active');
    // }
    // const next = () => {
    //     clear();
    //     const newIndex = (value + 1)% data.length;
    //     setValue(newIndex);
    //     thb[newIndex].classList.add('active')
    // }
    // const prev = () => {
    //     clear();
    //     const newIndex = (value - 1 + data.length)% data.length;
    //         setValue(newIndex);
    //     thb[newIndex].classList.add('active');
    // }
    function closeGallery(){
        // document.querySelector('.gallery').style.display = "none"
        dispatch(view({open: false}))
    }

  return (
    <>
    <Wrapper className="gallery">
        <Close src={close} close='true' onClick={closeGallery} alt="close"/>
        <MainWrap>
            <Swipe><Close src={prevbtn} alt="next" /></Swipe>
            <MainImg src={dsrc} />
            <Swipe><Close src={nextbtn} alt="prev" /></Swipe>
        </MainWrap>
        {/* <ThumbNailWrap>
            {data && data.map((item, index) => (
                <ThumbNail data-key={index} ref={thmbRef} src={item} className="thumbnails" onClick={reloadImg}></ThumbNail>
            ))}
        </ThumbNailWrap> */}
    </Wrapper>
    </>
  )
}

export default ViewGallery