import styled, {keyframes} from 'styled-components'

const grad = keyframes`
    from {
        background-image: linear-gradient(to right, grey , white);
    }
    to {
        background-image: linear-gradient(to right, white , grey);
    }
`

export const ListWrapper = styled.ul`
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    overflow-x: auto;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
        background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;       
    }
    &::-webkit-scrollbar:hover{
        -webkit-appearance: none;
        width: 4px;
    }
`
export const ListWrapperSkeleton = styled.ul`
    display: flex;
    flex-direction: column;
    animation: ${grad} 1s infinite;
`

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
    gap: 1%;
    height: 720px;
    /* border: 0.1px solid gray; */
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    margin-top: ${({top}) => top? '2rem':''};
`

export const ListContainerSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 720px;
    animation: ${grad} 1s infinite;
`

export const ListItem = styled.li`
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    width: 100%;
`

export const ListItemSkeleton = styled.li`
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    width: 100%;
    animation: ${grad} 1s infinite;
`

export const ListText = styled.p`
    color: ${({color}) => color? color : 'black'};
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-size: 0.8rem;
    padding-left: ${({pd}) => pd? '2%' : ''};
    padding-right: ${({pd}) => pd? '2%' : ''};
    width: ${({pd}) => pd? '' : '65%'};
    cursor: pointer;
    &:hover{
        color: rgb(5 88 149);
    }
`
export const ListSpan = styled.span`
    color: black;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-size: 0.8rem;
    padding: 0 2%;
    cursor: pointer;
    &:hover{
        color: rgb(5 88 149);
    }
`

export const ListTextSkeleton = styled.p`
    color: ${({color}) => color? color : 'black'};
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-size: 0.8rem;
    padding-left: ${({pd}) => pd? '2%' : ''};
    padding-right: ${({pd}) => pd? '2%' : ''};
    width: ${({pd}) => pd? '' : '65%'};
`
export const ListStrong = styled.strong`

`

export const ListProfile = styled.img`
    border-radius: 999px;
    height: 2rem;
    width: 2rem;
`
export const ListMini = styled.div`
    display: flex;
    padding: ${({top}) => top? '3%' : ''};
    margin-bottom: ${({top}) => top? '3%' : '2%'};
    justify-content: ${({top}) => top? 'space-evenly': 'space-between'};
`

export const Text = styled.p`
    color: grey;
    margin: 50% auto;

`