import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader"


export const ChartWrapper = styled.div`
    position: relative;
`

export const ChartContainer = styled.div`
    width: 139px;
`

export const ChartTotal = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 45.9%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    background: black;
    color: white;
    width: 6rem;
    height: 6rem;
    border-radius: 4rem;
    align-content: center;
    justify-content: center;
`
export const ChartText = styled.p``


export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`