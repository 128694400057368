import styled, {keyframes} from 'styled-components'


const open = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(90deg);
    }
`

// const expand = keyframes`
//     from {
//         width: 0;
//     }
//     to {
//         width: 35px;
//     }
// `

export const NavBar = styled.nav`
    background: rgba(17, 115, 171, 1);
    display: flex;
    flex-wrap: wrap;
    padding: 3% 5%;
    justify-content: space-between;
    align-content: center;
    height: 1.5rem;
    width: 100%;
    /* width: 85%; */
`
export const NavIcon = styled.img`
    &.rotate {
        animation: ${open} 1s ease;
    }
`