import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setAlert } from "../features/postSlice/post";
import axios from "axios";
import { store } from '../app/store';
import { postData } from '../features/postSlice/post';
import { signoutRequest } from '../features/purge/purge';
// functions


export const viewPassword = (name) => {
    const input = document.querySelector(`.${name}`)
    if(input.type === "password"){
        input.type = "text"
    } else {
        input.type = "password"
    }
}

export const ShortenStr = (text, char) => {
  let str = text.toString()
  if (str.length > char){
      str = `${str.slice(0, char)}...`
  }
  return str
}

export function formatNumber(number) {
    if (number < 1000) {
      return number.toString();
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + 'k';
    } else {
      return (number / 1000000).toFixed(1) + 'M';
    }
  }
  
export const PrivateRoutes = () => {
    // if(navigate(-1) === "/sell" && !user.hasRole){
    //   alert("you need a role to access this page")
    // }
    const isLoggedIn = useSelector((state) => state.signin.loggedIn)
    // const isLoggedIn = true
    // let auth = {'token': false}
    return (
            isLoggedIn?
            <Outlet />
            :
            <Navigate to="/login" />
            
    )
}

export const RemoveLog = () => {
    const isLoggedIn = useSelector((state) => state.signin.loggedIn)
    
    // let auth = {'token': false}
    return (
            !isLoggedIn?
            <Outlet />
            :
            <Navigate to="/" />
            
    )
}

export const fetchHandle = async (data) => {
  const {url, body, tokens} = data
  return   axios
          .post(url, body, 
              {
                  headers:{"Content-Type": "application/json", "Authorization": `JWT ${tokens}`}
              })
      
          .then( response =>  response)
}

export const fetchDataHandle = async (data) => {
  const {url, body, tokens} = data
  return   axios
            .get(url, body, 
                {
                    headers:{"Content-Type": "application/json", "Authorization": `JWT ${tokens}`}
                })
        
            .then( response =>  {
              return { data: response.data, error: null, status: response.status}
            })
            .catch(error => {
                return {
                    data: null,
                    error: error.response? error.response.data.error : error.message,
                    status: error.response?.status
                }
            })
}

export function SignOut(config, token, dispatch){
  dispatch(postData({url: `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`, config: config, data: {'refresh': token}}));
  store.dispatch(signoutRequest());
}

export function handleRequest(response, dispatch, config, refresh){
  if(response.status === 200 || response.status === 201){
      return "success"
  }else if(response.status ===  401){
      dispatch(setAlert({alert: true, message: 'Session has expired'}));
      SignOut(config, refresh, dispatch);
      return "unauthorized"
  }else if (response.status ===  400){
      dispatch(setAlert({alert: true, message: response.error}));
      return "bad request"
  }else{
      dispatch(setAlert({alert: true, message: 'Network Error'}));
      return "network"
  }
}