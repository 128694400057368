import React, { useState } from 'react'
import { FormIcon, FormInput, FormLabel, FormWrapper, Center, MiniPad } from './style'
import cross from '../../assets/icons/bcross.svg'
import { useDispatch } from 'react-redux'
import { click } from '../../features/registerSlice/rdataSlice'

const CheckedForm = () => {
    const dispatch = useDispatch();
    const [roles, setRoles] = useState('');
    function selectRole(role){
        setRoles(role);
        dispatch(click({role: role, adduser: false, create: true}))
    }
    
  return (
    <FormWrapper pt={'3%'}>
        <MiniPad>
            <FormLabel>
                Set a role for this user
            </FormLabel>
            <FormIcon src={cross} alt='close' onClick={() => dispatch(click({adduser: false}))} />
        </MiniPad>
        <MiniPad>
        <FormLabel for='admin'>{'admin (Can edit setting)'}</FormLabel>
        <Center className='box'>
            <FormInput type='radio' id='admin' name='admin' value='admin' 
            className='specifyColor'
            checked={roles === 'admin'? true: false } onChange={() => selectRole('admin')} ht={'5%'} />
        </Center>
        </MiniPad>
        <MiniPad bottom>
        <FormLabel for='worker'>{'worker (Cannot edit setting)'}</FormLabel>
        <Center className='box'>
            <FormInput type='radio' id='worker' name='worker' value='worker' className='specifyColor'
            checked={roles === 'worker'? true: false } onChange={() => selectRole('worker')}  ht={'5%'} />
        </Center>
        </MiniPad>
    </FormWrapper>
  )
}

export default CheckedForm