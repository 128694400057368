import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singleData, errorClear, successClear } from "../features/singleSlice/single";
import { LayoutRow } from './style'
import ListBusiness from '../components/user_list'
import { Business, Profile } from '../components/user_info'
import dummydata from '../data/userdata.json'
import { getData } from "../features/getSlice/get";
import ViewGallery from "../components/gallery";
import { store } from "../app/store";
import { postData, setAlert } from "../features/postSlice/post";
import { signoutRequest } from "../features/purge/purge";
 
const VerificationPage = () => {
    const user = useSelector((state) => state.signin.user)
    const dispatch = useDispatch();
    const data = useSelector((state) => state.single);
    const state = useSelector((state) => state.single.singledata);
    const view = useSelector((state) => state.data);
    const reload = useSelector((state) => state.put);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/role/view/unverified/`
    const url2 = `${process.env.REACT_APP_BASE_APP_URL}/role/detail/unverified/${data.role}/${data.user_id}/`
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const config = {
      "Content-Type": "application/json", 
      "Authorization": `JWT ${user.tokens.access}`
    }

    useEffect(()=>{
        dispatch(singleData({url: url2, config: config}));
        dispatch(getData({url: url, config: config}));
    },[])

    useEffect(() => {
      if(!reload.loading && reload.success){
        setTimeout(() => {
          dispatch(getData({url: url, config: config}));
        }, 2000)
      }
    }, [reload.loading])
    const list_data = useSelector((state) => state.get.data);

    useEffect(()=>{
      dispatch(singleData({url: url2, config: config}));
    },[data.user_id, data.role])


    useEffect(() => {
      // if (state.loading === false && state.error !== ""){
      //   if(state.status === 400){
      //       dispatch(setAlert({alert: true, message: state.error}));
      //       dispatch(errorClear());
      //     }else if (state.status === 401){
      //       dispatch(setAlert({alert: true, message: 'Session has expired'}));
      //       dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
      //       store.dispatch(signoutRequest());
      //     }else{
      //       dispatch(setAlert({alert: true, message: 'Network Error'}));
      //       dispatch(errorClear());
      //     }
      // }
      if (data.loading === false && data.error !== ""){
        if(data.status === 400){
            dispatch(setAlert({alert: true, message: data.error}));
            dispatch(errorClear());
          }else if (data.status === 401){
            dispatch(setAlert({alert: true, message: 'Session has expired'}));
            dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
            store.dispatch(signoutRequest());
          }else{
            dispatch(setAlert({alert: true, message: 'Network Error'}));
            dispatch(errorClear());
          }
      }
    }, [state.loading, data.loading])

  return (
   <>
   {view.open && <ViewGallery data={view.data} />}
    <LayoutRow top>
        <ListBusiness data={list_data} top={true}/>
        <Profile data={state? state: dummydata.users[0]} top={true}/>
        <Business data={state? state: dummydata.users[0]} />
    </LayoutRow>
   </>
  )
}

export default VerificationPage