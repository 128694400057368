import styled from 'styled-components'


export const FillerContainer = styled.div`
    /* border: 1px solid rgba(0, 0, 0, 0.25); */
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    width: 38%;
    display: flex;
    flex-direction: column;
    padding: 10% 4%;
`

export const FillerHeader = styled.h1`
    font-weight: 700; 
    text-align: center;
    margin-bottom: 45%;
    font-size: 1.4rem;
`

export const Filler = styled.img`
    height: 9rem;
`