import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



const initialState = {

    loading: false,
    data: [],
    error: '',
    chartData:{
      chartUser: {},
      chartProp: {},
      chartRole: {}
    }
}
      

export const getData2  = createAsyncThunk('data/getData2', (objdata) => {
    const {url, config} = objdata;
    return axios
            .get(url, {headers: config}
            )
            .then(response => response.data) 
        })
const getSlice2 = createSlice({
    name: 'data',
    initialState,
    reducers: {
      add: (state, action) => {
          state.chartData = {
              ...state.chartData,
          ...action.payload
          };
          
      }
    },
    extraReducers: builder => {
          builder.addCase(getData2.pending, state => {
            state.loading = true
            state.data = []
            state.error = ''
          })
          builder.addCase(getData2.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
          })
          builder.addCase(getData2.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
          });
    },
});


export default getSlice2.reducer
export const { add } = getSlice2.actions