import styled from "styled-components";



export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: rgb(0, 0, 0, 0.5);
    align-items: center;
    position: fixed;
    width: 84vw;
    bottom: 0;
    height: 97vh;
    z-index: 1;
`
export const MainWrap = styled.section`
    width: 72%;
    height: 84vh;
    display: flex;
    margin: auto;
`
export const MainImg = styled.img`
    width: 67%;
    margin: 0;
    border-radius: 7px;
    /* background-size: cover;
    background-repeat: no-repeat; */

`
export const Swipe = styled.button`
    background: transparent;
    border: none;
    width: 5%;
    opacity: 0.5;
    cursor: pointer;
`

export const ThumbNailWrap = styled.section`
    display: flex;
    justify-content: space-evenly;
    width: 100%;

`

export const ThumbNail = styled.span`
    background: url(${({src}) => src});
    background-size: cover;
    height: 4rem;
    width: 6%;
    cursor: pointer;
    &.active {
        border: 1px solid white
    }
`

export const Close = styled.img`
    height: 40px;
    cursor: pointer;
    /* margin-left: ${props => props.close? '54%' : ''}; */
    align-self: ${props => props.close? 'flex-end' : ''};
    margin-right: ${props => props.close? '3%' : ''};
    opacity: 0.7;
    &:hover{
        opacity: 1;
        /* box-shadow: 1px 1px 4px red; */
    }
`