import GlobalStyle from "./globalStyles";
import { 
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate        
} from "react-router-dom";
import BaseLayout from "./pages/base";
import DashboardPage from "./pages/dashboard";
import VerificationPage from "./pages/verification";
import SettingPage from "./pages/setting";
import Error from "./components/errors/error";
import { PrivateRoutes, RemoveLog } from "./utility";
import LoginPage from "./pages/login";
import LoginOTP from "./components/input/otp";



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
        <Route element={<RemoveLog />}>
            <Route path="/login" element={<LoginPage />}  exact/>
            <Route path="/verify" element={<LoginOTP />}  exact/>
        </Route>
        <Route path="/404" element={<Error/>}  exact/>
        <Route path="*" element={<Navigate to="/404" />}/>
        <Route element={<PrivateRoutes />} >
          <Route path="/" element={<BaseLayout/>} exact>
            <Route path="" element={<DashboardPage/>} exact />
            <Route path="/verification" element={<VerificationPage/>} exact />
            <Route path="/settings" element={<SettingPage/>} exact />

          </Route>
        </Route>
    </Route>
)
);
function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <RouterProvider router={router}  exact/>
    </div>
  );
}

export default App;
